import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BaseSelect from '../components/base-select'
import styles from './technical-support.module.css'

const TechnicalSupportPage = ({ data }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    iAm: '',
    needHelpWith: '',
    message: '',
  })
  const { formatMessage } = useIntl()

  const onInputChange = (event, field) => {
    const formDataCopy = { ...formData }
    formDataCopy[field] = event.target.value
    setFormData(formDataCopy)
  }

  const hasSecondParagraph =
    formatMessage({ id: 'pages.technicalSupport.p2' }) !== 'NULL'

  return (
    <Layout headerLinkColor="lg:text-purple">
      <SEO
        lang={formatMessage({ id: 'lang' })}
        title={formatMessage({ id: 'pages.technicalSupport.title' })}
        description={formatMessage({
          id: 'pages.technicalSupport.description',
        })}
      />

      <section className="pt-48 pb-32 bg-blue-lightest text-purple-darker">
        <div className="custom-container">
          <h1 className="heading">
            {formatMessage({ id: 'pages.technicalSupport.title' })}
          </h1>

          <p className="mt-12">
            {formatMessage({ id: 'pages.technicalSupport.p1' })}
          </p>

          {hasSecondParagraph && (
            <p className="mt-4">
              {formatMessage({ id: 'pages.technicalSupport.p2' })}
            </p>
          )}
        </div>

        <div className="relative custom-container custom-container--extralarge">
          <div className={styles.right}>
            <Img alt="Support 02" fluid={data.right.childImageSharp.fluid} />
          </div>
        </div>
      </section>

      <section className="py-32 bg-blue-darkest">
        <div className="custom-container">
          <form
            name="technical-support"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="technical-support" />

            <div className="hidden">
              <label>
                Don’t fill this out if you're human:
                <input type="hidden" name="bot-field" />
              </label>
            </div>

            <div className="form-field">
              <label className="form-label">
                {formatMessage({
                  id: 'pages.technicalSupport.form.fields.fullName',
                })}
              </label>

              <input
                name="fullName"
                type="text"
                className="form-control"
                onChange={event => onInputChange(event, 'fullName')}
                value={formData.fullName}
              />
            </div>

            <div className="sm:flex">
              <div className="form-field w-full sm:w-1/2 mr-4">
                <label className="form-label">
                  {formatMessage({
                    id: 'pages.technicalSupport.form.fields.email',
                  })}
                </label>

                <input
                  name="email"
                  type="email"
                  className="form-control"
                  onChange={event => onInputChange(event, 'email')}
                  value={formData.email}
                />
              </div>

              <div className="form-field w-full sm:w-1/2">
                <label className="form-label">
                  {formatMessage({
                    id: 'pages.technicalSupport.form.fields.phoneNumber',
                  })}
                </label>

                <input
                  name="phoneNumber"
                  type="tel"
                  className="form-control"
                  onChange={event => onInputChange(event, 'phoneNumber')}
                  value={formData.phoneNumber}
                />
              </div>
            </div>

            <div className="sm:flex">
              <div className="form-field w-full sm:w-1/2 mr-4">
                <label className="form-label">
                  {formatMessage({
                    id: 'pages.technicalSupport.form.fields.iAm.label',
                  })}
                </label>

                <BaseSelect
                  name="iAm"
                  value={formData.iAm}
                  onChange={event => onInputChange(event, 'iAm')}
                >
                  <option value="school">
                    {formatMessage({
                      id:
                        'pages.technicalSupport.form.fields.iAm.options.school',
                    })}
                  </option>
                  <option value="teacher">
                    {formatMessage({
                      id:
                        'pages.technicalSupport.form.fields.iAm.options.teacher',
                    })}
                  </option>
                  <option value="tutor">
                    {formatMessage({
                      id:
                        'pages.technicalSupport.form.fields.iAm.options.tutor',
                    })}
                  </option>
                  <option value="student">
                    {formatMessage({
                      id:
                        'pages.technicalSupport.form.fields.iAm.options.student',
                    })}
                  </option>
                  <option value="other">
                    {formatMessage({
                      id:
                        'pages.technicalSupport.form.fields.iAm.options.other',
                    })}
                  </option>
                </BaseSelect>
              </div>

              <div className="form-field w-full sm:w-1/2">
                <label className="form-label">
                  {formatMessage({
                    id: 'pages.technicalSupport.form.fields.needHelpWith.label',
                  })}
                </label>

                <BaseSelect
                  name="needHelpWith"
                  value={formData.needHelpWith}
                  onChange={event => onInputChange(event, 'needHelpWith')}
                >
                  <option value="school">
                    {formatMessage({
                      id:
                        'pages.technicalSupport.form.fields.needHelpWith.options.atSchool',
                    })}
                  </option>
                  <option value="home">
                    {formatMessage({
                      id:
                        'pages.technicalSupport.form.fields.needHelpWith.options.atHome',
                    })}
                  </option>
                  <option value="other">
                    {formatMessage({
                      id:
                        'pages.technicalSupport.form.fields.needHelpWith.options.other',
                    })}
                  </option>
                </BaseSelect>
              </div>
            </div>

            <div className="form-field">
              <label className="form-label">
                {formatMessage({
                  id: 'pages.technicalSupport.form.fields.message',
                })}
              </label>

              <textarea
                name="message"
                rows="4"
                className="form-control resize-none"
                value={formData.message}
                onChange={event => onInputChange(event, 'message')}
              />
            </div>

            <button type="submit" className="btn mt-2">
              {formatMessage({ id: 'pages.technicalSupport.form.fields.send' })}
            </button>
          </form>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    left: file(relativePath: { eq: "support/support-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    right: file(relativePath: { eq: "support/support-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default TechnicalSupportPage
